import * as React from 'react'
import ChevronUpSmall from 'wix-ui-icons-common/on-stage/ChevronUpSmall'
import ChevronDownSmall from 'wix-ui-icons-common/on-stage/ChevronDownSmall'
import classNames from 'classnames'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {classes} from './legend.st.css'
import {LegendItem} from './item'
import {LegendProps} from '.'

export const Legend = ({legendItems}: LegendProps) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()
  const [expanded, setExpanded] = React.useState(false)

  return (
    <div
      className={classNames(classes.container, {
        [classes.desktopContainer]: !isMobile,
      })}
      onClick={() => setExpanded(!expanded)}
      data-hook={DH.PRICES_LEGEND}
    >
      <Text className={classes.label}>
        {expanded ? (
          <>
            {t('seatings_legend_title_expanded')} <ChevronDownSmall />
          </>
        ) : (
          <>
            {t('seatings_legend_title')} <ChevronUpSmall />
          </>
        )}
      </Text>
      <ul className={classNames(classes.list, {[classes.listExpanded]: expanded})}>
        {legendItems.map(({color, price}, index) => (
          <LegendItem key={index} color={color} label={price} />
        ))}
      </ul>
    </div>
  )
}
