import * as React from 'react'
import {focusElement, addCircularTabFocus, PlaceWithTicketInfo} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {Tickets} from '../../tickets'
import {AccessibilityMode} from '../../accessibility-mode'
import {TicketDescription} from '../../ticket-description'
import s from './sidebar.scss'
import {SidebarProps} from '.'

export const Sidebar = ({
  showAccessibilityMode,
  onRemoveClick,
  onCheckoutClick,
  chooseSeatMode,
  placesInBasket,
  placesInBasketCount,
  setForcedPopover,
}: SidebarProps) => {
  const [selectedSeatForDescription, setSelectedSeatForDescription] = React.useState<PlaceWithTicketInfo>(null)

  React.useEffect(() => {
    focusElement({selector: `[data-hook="${DH.EVENT_INFO}"]`, canFocus: true})
    addCircularTabFocus(`[data-hook="${DH.CLOSE_BUTTON}"]`, `[data-hook="${DH.ACCESSIBILITY_MODE_BUTTON}"]`)
  }, [showAccessibilityMode])

  return (
    <div className={classNames(s.sidebar, {[s.accessibilityModeSidebar]: showAccessibilityMode})}>
      {showAccessibilityMode ? <AccessibilityMode setForcedPopover={setForcedPopover} /> : null}
      {!showAccessibilityMode ? (
        <Tickets
          placesInBasketCount={placesInBasketCount}
          placesInBasket={placesInBasket}
          showTickets={true}
          onRemoveClick={onRemoveClick}
          onCheckoutClick={onCheckoutClick}
          onTicketMoreInfoClick={setSelectedSeatForDescription}
          chooseSeatMode={chooseSeatMode}
        />
      ) : null}
      {!showAccessibilityMode ? (
        <TicketDescription
          selectedSeatForDescription={selectedSeatForDescription}
          onClose={() => setSelectedSeatForDescription(null)}
        />
      ) : null}
    </div>
  )
}
