import * as React from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {Summary} from '../summary'
import {TicketInfo} from '../ticket-info'
import {AccessibilityModeButton} from '../accessibility-mode-button'
import {Legend} from '../plan/legend'
import s from './footer.scss'
import {FooterProps} from '.'

export const Footer = ({selectedPlace, withoutBreakdown, onAddClick, onCloseClick, onCheckoutClick}: FooterProps) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()

  return (
    <>
      {isMobile ? null : <AccessibilityModeButton />}
      <div className={classNames(s.footer, {[s.mobileFooter]: isMobile})}>
        {isMobile ? <Legend t={t} /> : null}
        <div className={s.container}>
          {selectedPlace ? (
            <TicketInfo place={selectedPlace} onAddClick={onAddClick} onCloseClick={onCloseClick} />
          ) : (
            <Summary withoutBreakdown={withoutBreakdown} onCheckoutClick={onCheckoutClick} />
          )}
        </div>
      </div>
    </>
  )
}
