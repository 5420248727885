import {
  isRegistrationClosedVisible,
  getEventTicketLimitPerOrder,
  shouldTicketLimitTooltipShow,
  hasSeatingPlan,
} from '@wix/wix-events-commons-statics'
import {getSelectedTicketsQuantity, getSelectedTicketQuantity} from '../../../../selectors/selected-tickets'
import {getButtonStyle, isHollowButton} from '../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {TicketOwnProps, TicketRuntimeProps} from './interfaces'
import {Ticket as MobilePresentation} from './mobile-ticket'
import {Ticket as Presentation} from './ticket'

const mapRuntime = ({state}: DetailsPageAppProps, {ticket: {id}}: TicketOwnProps): TicketRuntimeProps => {
  const {
    component: {settings},
    event,
  } = state
  const maxTicketsPerReservation = getEventTicketLimitPerOrder(event)
  const selectedQuantity = getSelectedTicketQuantity(state, id)
  const availableTicketsQuantity = maxTicketsPerReservation - getSelectedTicketsQuantity(state)

  return {
    selectedQuantity,
    isHollowButton: isHollowButton(getButtonStyle(settings)),
    availableTicketsQuantity,
    registrationClosed: isRegistrationClosedVisible(event),
    maxTicketsPerReservation,
    shouldTicketLimitTooltipShow: shouldTicketLimitTooltipShow(availableTicketsQuantity, selectedQuantity),
    withSeatingPlan: hasSeatingPlan(event),
  }
}

export const Ticket = connect<TicketOwnProps, TicketRuntimeProps>(mapRuntime)(Presentation)
export const MobileTicket = connect<TicketOwnProps, TicketRuntimeProps>(mapRuntime)(MobilePresentation)
export * from './interfaces'
