import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import classNames from 'classnames'
import {Summary} from '../summary'
import {EventInfo} from '../event-info'
import {SlideInModal} from '../../slide-in-modal'
import {TicketsListContainer} from '../tickets-list-container'
import {Ticket} from '../ticket'
import {SummaryContainer} from '../summary-container'
import {AccessibilityModeButton} from '../accessibility-mode-button'
import {classes} from './tickets.st.css'
import {TicketsProps} from '.'

export const Tickets = ({
  showTickets,
  placesInBasket,
  showAccessibilityMode,
  onBackClick,
  onRemoveClick,
  onCheckoutClick,
  onTicketMoreInfoClick,
  chooseSeatMode,
  placesInBasketCount,
}: TicketsProps) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()
  const desktop = !isMobile
  const selectedPlacesCount = placesInBasket.length

  return (
    <SlideInModal
      backButtonText={showAccessibilityMode ? t('seatings_backToList') : t('seatings_backToMap')}
      show={showTickets}
      onBackClick={onBackClick}
    >
      {!chooseSeatMode && <EventInfo />}
      {!chooseSeatMode && (
        <Text className={classes.title} tagName="H2">
          {selectedPlacesCount === 1
            ? t('seatings_yourTicket')
            : t('seatings_yourTickets', {count: selectedPlacesCount})}
        </Text>
      )}
      {desktop && !placesInBasketCount ? (
        <Text className={classNames(classes.subtitle, {[classes.subtitlePaddingTop]: chooseSeatMode})} tagName="p">
          {t('seatings_tickets_emptyState_description')}
        </Text>
      ) : null}
      <TicketsListContainer chooseSeatMode={chooseSeatMode}>
        {isMobile && !placesInBasketCount ? (
          <Text className={classes.mobileEmptyState}>{t('seatings_tickets_emptyState_description')}</Text>
        ) : null}
        {placesInBasket.map((place, index) => (
          <Ticket
            inBasket={true}
            key={place.id}
            lastTicket={index === placesInBasketCount - 1}
            place={place}
            onRemoveClick={onRemoveClick}
            onTicketMoreInfoClick={onTicketMoreInfoClick}
          />
        ))}
      </TicketsListContainer>
      {desktop ? <AccessibilityModeButton /> : null}
      {!chooseSeatMode && (
        <SummaryContainer inTickets={true}>
          <Summary inTickets={true} onCheckoutClick={onCheckoutClick} onBackClick={onBackClick} />
        </SummaryContainer>
      )}
    </SlideInModal>
  )
}
