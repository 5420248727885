import * as React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {PRIORITY, Button} from 'wix-ui-tpa/Button'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {useColors} from '../../../../../../hooks/colors'
import {classes} from './empty-state.st.css'
import {EmptyStateProps} from '.'

export const EmptyState = ({onButtonClick}: EmptyStateProps) => {
  const {t} = useTranslation()
  const {darkColor} = useColors()

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{t('seating_accessibilityMode_emptyState_title')}</Text>
      <Text className={classes.description}>{t('seating_accessibilityMode_emptyState_description')}</Text>
      <Button
        className={classNames(classes.button, {[classes.buttonNotWired]: !darkColor})}
        data-hook={DH.RESET_FILTERS}
        priority={PRIORITY.secondary}
        onClick={onButtonClick}
      >
        {t('seating_accessibilityMode_emptyState_action')}
      </Button>
    </div>
  )
}
