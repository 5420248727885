import {hasSeatingPlan} from '@wix/wix-events-commons-statics'
import {SelectedTickets, State} from '../types'
import {getSelectedTicketsFromSeats, getTicketsToPlaces} from './seating'
import {getTicketOrderIndexById, getTicketById} from './tickets'

export const getSelectedTickets = (state: State): SelectedTickets =>
  hasSeatingPlan(state.event) ? getSelectedTicketsFromSeats(state) : state.selectedTickets

export const getSelectedTicketsQuantity = (state: State) => {
  const selectedTickets = getSelectedTickets(state)
  return Object.keys(selectedTickets).reduce(
    (sum: number, ticketId: string) => selectedTickets[ticketId].quantity + sum,
    0,
  )
}

export const getSelectedTicketQuantity = (state: State, ticketId: string, seatId?: string) => {
  if (seatId) {
    return getTicketsToPlaces(state)[ticketId]?.filter(id => id === seatId).length
  }
  return state.selectedTickets[ticketId]?.quantity ?? 0
}

export const getSortedSelectedTickets = (state: State) => {
  const selectedTickets = getSelectedTickets(state)
  const nonZero = (ticketDefId: string) => Boolean(selectedTickets[ticketDefId]?.quantity)

  const byTicketOrder = (first: string, second: string) =>
    getTicketOrderIndexById(state.tickets, first) - getTicketOrderIndexById(state.tickets, second)

  return Object.keys(selectedTickets)
    .filter(nonZero)
    .sort(byTicketOrder)
    .reduce(
      (sortedSelectedTickets: SelectedTickets, id: string) => ({
        ...sortedSelectedTickets,
        [id]: selectedTickets[id],
      }),
      {},
    )
}

export const getSelectedTicketsArray = (state: State): wix.events.ticketing.Ticket[] =>
  Object.entries(getSortedSelectedTickets(state)).reduce((tickets, [ticketDefId, {quantity}]) => {
    const ticket = getTicketById(state.tickets, ticketDefId)
    tickets.push(...Array(quantity).fill(ticket))
    return tickets
  }, [])

export const getTotalOrderRevenue = (state: State) => {
  const selectedTickets = getSelectedTickets(state)

  return Object.keys(selectedTickets).reduce(
    (sum, ticketId) =>
      sum + Number(getTicketById(state.tickets, ticketId).price.amount) * selectedTickets[ticketId].quantity,
    0,
  )
}

export const getNonFreeSelectedTicketsCount = (state: State) => {
  const selectedTickets = getSelectedTickets(state)

  return Object.keys(selectedTickets)
    .filter(ticketId => state.tickets.find(ticket => ticket.id === ticketId && !ticket.free))
    .reduce((sum, ticketId) => sum + selectedTickets[ticketId].quantity, 0)
}
