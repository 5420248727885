import {getFormMessages, isRsvp} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {hasAgreedWithPolicies, getPolicies, shouldShowAlert} from '../../selectors/policies'
import {isPaidTicketSelected} from '../../selectors/tickets'
import {getRsvpError} from '../../selectors/rsvp'
import {getSelectedTickets} from '../../selectors/selected-tickets'
import {Policies as Presentation} from './policies'
import {PoliciesRuntimeProps, PoliciesOwnProps} from './interfaces'

const mapRuntime = (
  {state, actions: {toggleAgreePolicies, openPolicyModal}}: DetailsPageAppProps,
  {t}: PoliciesOwnProps,
): PoliciesRuntimeProps => {
  const event = state.event
  const rsvp = isRsvp(event)

  return {
    agreedWithPolicies: hasAgreedWithPolicies(state),
    messages: getFormMessages(event),
    rsvp,
    policies: getPolicies(state),
    showAlert: shouldShowAlert(state),
    paidTicketSelected: isPaidTicketSelected(state.tickets, getSelectedTickets(state)),
    error: getRsvpError(state, t),
    toggleAgreePolicies,
    openPolicyModal,
  }
}

export const Policies = connect<PoliciesOwnProps, PoliciesRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
