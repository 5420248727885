import {Button} from 'wix-ui-tpa/Button'
import React from 'react'
import classNames from 'classnames'
import {isHollowButton, isRoundedButton} from '../../../../selectors/settings'
import {classes} from './button-style.st.css'
import {ButtonStyleProps} from './interfaces'

export const ButtonStyle = ({dataHook, onClick, text, disabled = false, buttonStyle}: ButtonStyleProps) => {
  const hollowButton = isHollowButton(buttonStyle)
  const roundedButton = isRoundedButton(buttonStyle)

  return (
    <Button
      data-hook={dataHook}
      fullWidth
      upgrade
      className={classNames(classes.button, {
        [classes.hollow]: hollowButton,
        [classes.rounded]: roundedButton,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}
