import {getEventTicketLimitPerOrder, saleScheduled} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {isChooseSeatMode} from '../../../../selectors/navigation'
import {isTicketLimitReached} from '../../../../selectors/seating'
import {getSelectedTicketQuantity, getSelectedTicketsQuantity} from '../../../../selectors/selected-tickets'
import {getTicketById} from '../../../../selectors/tickets'
import {TicketInfoRuntimeProps, TicketInfoOwnProps} from './interfaces'
import {TicketInfo as Presentation} from './ticket-info'

const mapRuntime = (
  {state, actions: {addPlaceToBasket, setError}}: DetailsPageAppProps,
  {place}: TicketInfoOwnProps,
): TicketInfoRuntimeProps => {
  const ticket = getTicketById(state.tickets, place?.ticketId)

  return {
    selectedTicketQuantity: getSelectedTicketQuantity(state, place?.ticketId, place?.id),
    selectedTicketsQuantity: getSelectedTicketsQuantity(state),
    showAccessibilityMode: state.seating.showAccessibilityMode,
    chooseSeatMode: isChooseSeatMode(state),
    addPlaceToBasket,
    ticketLimitReached: isTicketLimitReached(state),
    setError,
    scheduled: ticket ? saleScheduled(ticket) : undefined,
    eventTicketLimitPerOrder: getEventTicketLimitPerOrder(state.event),
  }
}

export const TicketInfo = connect<TicketInfoOwnProps, TicketInfoRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
